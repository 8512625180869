@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-display: block;
  font-family: "Neometric";
  src: url("../fonts/Neometric.otf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: "Neometric-Light";
  src: url("../fonts/Neometric_light.otf") format("truetype");
}

body {
  font-family: "Neometric-Light";
  font-weight: 500;
  background-color: #1E2128;
  color: white;
  font-size: 12px;
}

.styled-bar::-webkit-scrollbar {
  background: #2a2a30;
  width: 8px;
}

.styled-bar::-webkit-scrollbar-thumb {
background: #31313a;
border-radius: 10px;
}

.p-inputtext {
  border: none !important;
  background: #ffffff05 !important;
}

.p-disabled {
  color:#ffffff62
}

.popup {
  color: black;
}

.p-carousel-item {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}